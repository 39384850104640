import React from 'react'

import '../ServePost.css'

import momentum0 from '../../../assets/fashion/item_momentum/momentum_000.png'
import momentum1 from '../../../assets/fashion/item_momentum/momentum_001.png'
import momentum2 from '../../../assets/fashion/item_momentum/momentum_002.png'
function FashionPost5() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={momentum0} alt="" />
            <img className='content-img' src={momentum1} alt="" />
            <img className='content-img' src={momentum2} alt="" />
        </div>
    )
}


export default FashionPost5