import React from 'react'

import '../ServePost.css'

import chadadam0 from '../../../assets/visual/bx_chadadam/chadadam_000.png'
import chadadam1 from '../../../assets/visual/bx_chadadam/chadadam_001.png'
import chadadam2 from '../../../assets/visual/bx_chadadam/chadadam_002.png'
import chadadam3 from '../../../assets/visual/bx_chadadam/chadadam_003.gif'
import chadadam4 from '../../../assets/visual/bx_chadadam/chadadam_004.png'
import chadadam5 from '../../../assets/visual/bx_chadadam/chadadam_005.png'
function VisualPost15() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={chadadam0} alt="" />
            <img className='content-img' src={chadadam1} alt="" />
            <img className='content-img' src={chadadam2} alt="" />
            <img className='content-img' src={chadadam3} alt="" />
            <img className='content-img' src={chadadam4} alt="" />
            <img className='content-img' src={chadadam5} alt="" />
        </div>
    )
}

export default VisualPost15