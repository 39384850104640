import React from 'react'

import '../ServePost.css'

import whitenight0 from '../../../assets/visual/bx_whitenight/whitenight_000.png'
import whitenight1 from '../../../assets/visual/bx_whitenight/whitenight_001.png'
import whitenight2 from '../../../assets/visual/bx_whitenight/whitenight_002.png'
import whitenight3 from '../../../assets/visual/bx_whitenight/whitenight_003.png'
import whitenight4 from '../../../assets/visual/bx_whitenight/whitenight_004.png'

function VisualPost10() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={whitenight0} alt="" />
            <img className='content-img' src={whitenight1} alt="" />
            <img className='content-img' src={whitenight2} alt="" />
            <img className='content-img' src={whitenight3} alt="" />
            <img className='content-img' src={whitenight4} alt="" />
        </div>
    )
}

export default VisualPost10