import React from 'react'

import '../ServePost.css'

import khu0 from '../../../assets/fashion/film_khushow/khushow_000.png'
import khu1 from '../../../assets/fashion/film_khushow/khushow_001.png'
import khu2 from '../../../assets/fashion/film_khushow/khushow_002.png'
import khu3 from '../../../assets/fashion/film_khushow/khushow_003.png'

function FashionPost7() {
    return (
        <div className='serve-content-body'>
            <img className='content-img' src={khu0} alt="" />
            <img className='content-img' src={khu1} alt="" />
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/988441711?background=1`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <img className='content-img' src={khu2} alt="" />
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/994561047?h=5fb64a2ac7`}
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/994561520?h=25724e98c6`}
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/994563601?h=7f9dff3d61`}
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/994561945?h=34b3bff527`}
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/994564673?h=058c75a9fc`}
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/994565787?h=28e3381ef1`}
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <img className='content-img' src={khu3} alt="" />
        </div>
    )
}

export default FashionPost7