import React from 'react'

import '../BlogPost.css'

import blog4_1 from '../../../assets/blog/blog4/1.png'
import blog4_2 from '../../../assets/blog/blog4/2.jpeg'
import blog4_3 from '../../../assets/blog/blog4/3.png'
import blog4_4 from '../../../assets/blog/blog4/4.png'
import blog4_5 from '../../../assets/blog/blog4/5.png'
import blog4_6 from '../../../assets/blog/blog4/6.png'
import blog4_7 from '../../../assets/blog/blog4/7.png'
import blog4_8 from '../../../assets/blog/blog4/8.png'
import blog4_9 from '../../../assets/blog/blog4/9.png'
import blog4_10 from '../../../assets/blog/blog4/10.png'
import blog4_11 from '../../../assets/blog/blog4/11.png'
import blog4_12 from '../../../assets/blog/blog4/12.png'
import blog4_13 from '../../../assets/blog/blog4/13.png'
import blog4_14 from '../../../assets/blog/blog4/14.png'
import blog4_15 from '../../../assets/blog/blog4/15.png'
import blog4_16 from '../../../assets/blog/blog4/16.png'
import blog4_17 from '../../../assets/blog/blog4/17.png'

function Post4() {
    return (
        <div className='blog-content-body'>
            <hr></hr>
            <br></br>
            <br></br>
            <div className='blog_title'><b>프롤로그</b></div>
            <div className='blog_subtitle'>강의 후기 전반적 평</div>
            <div className='article'>
            <br></br>올해 4월 패스트 캠퍼스에서 진행하는 플러스엑스 BX 실무 마스터 패키지를 완강했다.
            <br></br>
            <br></br>
            <img className='content-img' src={blog4_1} alt="" />
            <br></br>
            <br></br>저번 UX 실무 마스터 패키지 수강후기 3등으로 10% 쿠폰이 당첨돼서 저렴하게 구매했지만 가격이.. 
            <span className='tip'>저번 강의 가격이랑 앞자리가 다르던데요?</span> 망설이다 선착순 200명에 못 들어 굿즈는 못 받았다.
            <br></br>
            <br></br><img className='content-img' src={blog4_2} alt="" />
            <br></br><span className='tip'>역시 고민은 배송만 늦출 뿐</span>
            <br></br>
            <br></br>
            <br></br>블로그 글을 자주 쓰는 편은 아니지만 저번 글로 경품을 받아 이번엔 더 잘 써보고자 하는 것도 있고, PXSX에서 진행하는 BX 워크숍에 참여하고 싶었던 것도 있다. 12명 안에 못 들어가면 떨어지는 서바이벌 구조가 상당히 살 떨리긴 하지만 안되더라도 복습 겸 강의를 한 번 더 회독하려 한다.
            <br></br>
            <br></br>전반적인 BX 강의 명은 플러스 엑스 신입 입사자에게 교육용으로 사용하는 교본의 느낌이랄까..  A부터 Z까지 꼼꼼히 알려주는 느낌이다. 채용을 전제로 워크숍을 무료 진행하는 거라면 일리 있는 말이다. 실제로 디자이너스 커뮤니티에서, 연차 된 실무자 기준으로 UX는 도널드 노먼을, BX는 마케팅 책을 읽는 게 더 낫다는 의견이 여럿 있었다. 개인적으로 이런 블로그 글을 사전과제로 올리게 하였을 때 강의 내용을 세부적으로 올리게 되니 잠재적 소비자들이 강의 내용을 대강 엿보고 안 들을 수도 있을 텐데 라는 우려를 했다. 마케팅을 이렇게 한다고? 생각했지만, 플러스 엑스에 입사할 사람들에게 기존 직원들의 리소스를 빠르게 압축해 효율적으로 교육하기 위한 이유라고 한다면 좋은 전략이라고 생각했다. 그래서 대충 하면 안 되겠구나.. 생각했다. 
            </div>
            <br></br>
            <br></br>
            <br></br>
            <hr></hr>
            <br></br>
            <br></br>
            <br></br>
            <div className='blog_title'><b>#1</b></div>
            <div className='blog_subtitle'>BX 디자인 프로세스 전략</div>
             <div className='article'>
            <br></br><br></br><b>BX 플러스 엑스 강의는 가볍게 단위를 나누자면 이렇다.</b>
            <br></br>1.BX 디자인 프로세스 전략
            <br></br>2.BX 엘리먼츠, 애플리케이션
            <br></br>3.BX 디자인 가이드와 일관성
            <br></br><br></br>1번 BX 디자인 프로세스 전략은 이론이기 때문에 실무자들에겐 조금 지루할 수도 있다. <span className='tip'>그래도 기본을 되짚는 게 제일 중요한 것 아닌가? 본인은 나쁘지 않다고 본다. </span>
            <br></br><br></br> <img className='content-img' src={blog4_3} alt="" />
            <br></br>
            <br></br><b>철저히 에이전시의 입장으로서 클라이언트를 대응하는 방법에 나오는데, 본인에겐 너무나도 필요한 내용이다.</b>
            <br></br>
            <br></br>디자이너가 생각하는 브랜드의 이미지, 엔지니어가 생각하는 브랜드의 이미지, 오너가 생각하는 브랜드의 이미지, 소비자가 생각하는 브랜드의 이미지 등 브랜드를 경험하는 모든 입장이 공통될 수는 없다. 사람은 누구나 다른 생각을 할 수 있고 제각기 다른 기준을 가지고 있으니 어쩌면 당연한 결론이다. 디자이너는 시각적인 산출물로 성과를 낼 수 있음을 증명하고, 그들을 설득해야 하는 role이다. 그러므로 한 회사의 브랜딩을 할 때는 그들의 입장을 모두 들어보고 리서치를 시작하는 것이 가장 효율적인 방법이라고 생각한다. 플러스 엑스에서는 이런 과정을 세심하고 깊게 가르쳐 주어서 어떻게 그들의 입장을 들어야 하는지 감이 잡혀 좋았다. 집요하게 질문했던 예시도 적어두었다가 써먹어야겠다.
            <br></br>
            <br></br><img className='content-img' src={blog4_4} alt="" />
            <br></br>
            <br></br><b>다만.. 리서치는 조금 규격화되어있는 것 같다. </b>
            <br></br>필자는 플러스 엑스가 BX 강의를 론칭하기 전에 클래스 101 큐리 브랜딩 강의와 coloso 필립 킴 강의를 먼저 들어본 상태다. 언급한 강의들은 소규모 브랜드나 론칭할 새 프로젝트 등 브랜드에 다루고, 직접 예시작 업을 해주며 더 범용성이 넓은 강의들이다. 플러스 엑스는 그에 반해 이미 브랜드가 있거나 큰 회사를 상대로 초점이 맞춰져있다는 느낌이 들었다. 에어 전시기에 당연한 일이겠지만 초보자 입장에서 이런 방식만 알아두는 건 다른 범위의 업무를 할 때 위험할 수도 있겠다 생각이 든다. 물론 좋은 강의이고 효율적인 프로세스임에는 분명하지만 역시 디자인은 정답은 없기 때문에 많은 곳에서 공부해야 하는 것 같다.
            <br></br>
            <br></br><img className='content-img' src={blog4_5} alt="" />
            <br></br> 
            <br></br><b>디자인 전략을 구축하는 과정은 상당히 디테일하다</b>
            <br></br>에이전시에서 일해보지 않는 이상 회사를 단독으로 상대할 일은 없는데, 이런 경험을 간접적으로 경험시켜준다.

회사를 하나 정하고 다양한 레퍼런스를 보여주어 어떻게 그들의 생각을 좁혀나갈 것이며, 시각적으로 구현할 것인지?에 대해 세심한 프로세스를 보여준다. 무드 보드만 해도 여러 과정을 거쳐 아이데이션하고 그것을 또 여러 후보로 뻗어나가 최종 무드 보드를 산출한다.
            </div>
            <br></br>
            <br></br>
            <br></br>
            <hr></hr>
            <br></br>
            <br></br>
            <br></br>
            <div className='blog_title'><b>#2</b></div>
            <div className='blog_subtitle'>BX 엘리먼츠, 애플리케이션</div><br></br>
            <div className='article'>
            <br></br>
            <br></br><b>2번 BX 엘리먼츠, 어플리케이션은 실제 플러스 엑스의 사례를 디테일하게 보여준다​</b>
            <br></br>이전 챕터보다 눈이 훨씬 즐겁고 이해도 쉽다.
            <br></br>
            <br></br><img className='content-img' src={blog4_6} alt="" />
            <br></br>
            <br></br><b>엘리먼츠 과정에서는 로고, 컬러, 타이포를 집중에서 가르친다.</b>
            <br></br>일러스트, 포토, 공간, 사운드도 곁들여서 강의가 있지만 주관적인 생각으로 로고와 컬러를 굉장히 집중한다는 느낌을 받았다. 브랜드 예시 사례를 보여줄 때는 로고 시안을 확장성을 고려한 레퍼런스들과 함께 보여주는데, 정말 하나같이 예술이다.. 천재적이다. 저런 응용력이라니!! 개인적인 경험으로 브랜딩을 열심히 만들어도 저런 확장성 때문에 설득이 오래 걸린다. 
            <br></br>
            <br></br><b>그리고 플러스엑스가 일을 엄청 많이 해왔다는 게 피부에 느껴졌다.</b>
            <br></br>
            <br></br>
            <br></br><img className='content-img' src={blog4_7} alt="" />
            <br></br>
            <br></br>브랜드 코니의 폰트 선정이다. 전략 부분에서 폰트를 선정하는 과정 필자도 정말 중요하게 생각하는 부분이다. www 전시 프로젝트를 할 때도 어울리는 폰트가 없어 무척 당황했던 경험이 있다. 결국 직접 만들었던.. 브랜드 내에 폰트가 주는 영향력은 이런 과정을 세심이 짚고 넘어가는 것을 보여준 것이 너무 좋았다.
            <br></br>
            <br></br><img className='content-img' src={blog4_8} alt="" /><img className='content-img' src={blog4_9} alt="" />
            <br></br>해당 챕터 내에서 가장 좋았던 부분. 로고가 변형되는 과정을 모두 보여주어 클라이언트가 확신을 가질 수 있도록 돕는 과정이 긴박했다. 역시 수정을 적게 하는 것이 디자이너의 역량에 달려있다는 것.. 이걸 보면서도 느낀다.
            로고의 변형된 모든 과정을 보여주어 선택된 로고가 제일 괜찮다는 확신을 들게 하고 수정을 원한다 해도 해당 장표에서 선택할 수 있게 만드는 것이 커뮤니케이션 과정을 최소화할 수 있는 방법이구나. 최종 최최종 최최 최종을 계속 하나로 하지 말고 저렇게 동시다발적으로 보여주는 것이 업무 효율적으로 좋다는 것을 배웠다.
            <br></br>
            <br></br><img className='content-img' src={blog4_10} alt="" />
            <br></br>
            <br></br><b>보다 보니 좀 광기가 보이는 것 같기도..</b>
            <br></br>
            <br></br><img className='content-img' src={blog4_11} alt="" />
            <br></br>
            <br></br>브랜드 컬러 시스템을 저렇게 물어보고 소통하고 고민하는 과정이 잘 와닿았다. 이걸 계기로 지금 운영하고 있는 OOBB 크루도 리브랜딩 하기로 마음먹었다. 그래서 바로 엑셀 장표 작성하고 인터뷰 진행 중에 있다.
            <br></br>
            <br></br>브랜드의 기존 자산을 통일시키고 지향가치와 특성 그리고 차별화를 통합하는 컬러는 강력할 것이다. 브랜드가 하는 이야기를 응축해서 담는 것은 버벌, 쉐잎뿐만 아니라 컬러도 큰 영향력을 발휘한다는 것을 다시금 되새기게 되는 챕터였다.
            <br></br>
            <br></br><b>애플리케이션 과정에선 키 비주얼을 자세히 보여준다. ​</b>
            <br></br>
            <br></br><img className='content-img' src={blog4_12} alt="" />
            <br></br>
            <br></br>앞선 천재적이다!!!! 했던 확장성에 대해 자세하고 디테일한 가이드라고 보면 된다. 브랜딩이 확정되면 전개에 대한 가이드를 제시해 주어야 하는데 그것을 키 비주얼이라고 불린다.
            <br></br>
            <img className='content-img' src={blog4_13} alt="" />
            <br></br>
            <br></br>애플이 한국의 민화를 가지고 브랜딩 한 예시 사례를 보여주었는데. 키 비주얼이 의미하는 이런 확장성 예시로 바로 이해된다.현재 널리 알려진 카카오 페이지를 예시로
            <br></br>
            <br></br><img className='content-img' src={blog4_14} alt="" /><img className='content-img' src={blog4_15} alt="" />
            <br></br>
            <br></br>심볼 형태의 키 비주얼을 어떤 식으로 확장해나가며 응용할 수 있는지, 카카오 구성원들에게 가이드를 제시하는 역할을 하는 것을 보여준다. 이런 가이드가 있다면 확실히 콘텐츠를 만들어 나가는 데에 있어서 편리할 것 같다. 올리브 영에서도 브랜드 디자인팀에서 올 영세일 키 비주얼을 작성해서 보내줄 때, 방송그래픽으로 콘텐츠 확장 시 수월했던 경험이 있다. OSMU : One Soaurce-Multi Use 개념이 떠오르는 프로세스.
            </div>
            <br></br>
            <br></br>
            <br></br>
            <hr></hr>
            <br></br>
            <br></br>
            <br></br>
            <div className='blog_title'><b>#3</b></div>
            <div className='blog_subtitle'>3.BX 디자인 가이드와 일관성</div><br></br>
            <div className='article'>
            <br></br><b>BX 디자인 가이드와 일관성은 마무리 챕터라고 볼 수 있다.​</b>
            <br></br>
            <br></br><img className='content-img' src={blog4_16} alt="" />
            <br></br>
            <br></br><b>지금까지의 과정을 한눈에 정리하는 장표이다.</b>
            <br></br>개인적으로 확실히 on-air 되고 수익 및 성과를 내었다! 하는 부분은 3번 디자인 전략과 7번 브랜드 가이드라인 부분이고, 그 사이사이의 프로세스는 경험해 본 적이 없다. 뭉뚱그려 유추한 과정을 디테일하게 적어주니 그동안의 작업에서 빈틈이 보이는 것 같다. 항상 디자이너로서 심미적 기준을 본인에게 두었고 디자인을 요청하는 이의 생각을 물어본 적이 없다. 인터뷰 과정이 생략되었으니 그들이 시안을 마음에 들어 하지 않는 것은 당연한 일. 1번 가장 기초적인 부분이 스스로를 제일 반성케 한 부분이다. 
            </div>
            <br></br>
            <br></br>
            <br></br>
            <hr></hr>
            <br></br>
            <br></br>
            <br></br>
            <div className='blog_title'><b>에필로그</b></div>
            <div className='blog_subtitle'>다음 강의는 BX</div><br></br>
            <div className='article'>
            <br></br><b>이번 플러스 엑스의 BX 강의는​</b>
            <br></br>저번 UX 강의에서 설문 및 블로그 글을 참고해 사례를 많이 보여주는 쪽으로 개선한 것 같다. 개인적으로 BX 강의는 재미있게 시청했다. 이번 강의도 글이 많긴 했지만 UX 과정에 비해 시각적인 산출물을 많이 보여준 듯하다. 에이전시 입장에서 클라이언트에게 제공한 서비스를 교육용 목적으로 사용함에 있어, 어려운 과정이 있었을 것 같은데 그럼에도 불구하고 다양한 사례를 보여준 것에 대해 학습자 입장에서 감사했다. 
            <br></br>
            <img className='content-img' src={blog4_17} alt="" />
            <br></br>
            <br></br>해당 강의를 보고 지금 이끌고 있는 OOBB 크루의 디자인 방향성을 반영한 리브랜딩을 위해 플러스 엑스 스타일의 인터뷰를 진행하고 있다. 확실히 효율적이고 인터뷰어들도 즐겁게 임해서 수월하게 진행되고 있는 편.
            <br></br>
            <br></br>해당 강의를 보고 지금 이끌고 있는 OOBB 크루의 디자인 방향성을 반영한 리브랜딩을 위해 플러스 엑스 스타일의 인터뷰를 진행하고 있다. 확실히 효율적이고 인터뷰어들도 즐겁게 임해서 수월하게 진행되고 있는 편.
            <br></br>
            <br></br><b>아쉬운 점은 아래와 같다.</b>
            <br></br>1. 너무 대본 읽는 듯한 어조
            <br></br>:강의 장표 자체에 글과 그림이 많아 순간 습득해야 할 정보량이 많은 편인데 집중이 더 안되는 느낌이다.
            <br></br>2. 챕터별로 너무 자주 바뀌는 담당자 목소리
            <br></br>:강의를 볼 때 일관성을 깨뜨린다.
            <br></br>3. 프로세스를 직접 시연하는 이벤트 부재
            <br></br>:실전을 경험하지 못하고 이론만 디테일한 느낌이기에 과정 이해가 쉽지 않다.
            <br></br>
            <br></br>이번 강의는 시각적으로 세심하게 설명도 많은 편이고 저번 강의보단 친절한 편이였다. 해당 수업을 녹여내서 oobb브랜딩을 성공적으로 마쳐야지!
            </div>
        </div>
    )
}

export default Post4