import React from 'react'

function NotFound() {
    return (
        <div className='page'>
            <div className='flex'>
                <div className='margin-auto' style={{ marginTop:'26rem', fontSize:'3rem' }}>404 Not Found</div>
            </div>
        </div>
    )
}

export default NotFound