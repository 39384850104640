import React from 'react'

import '../ServePost.css'

import nuveo0 from '../../../assets/visual/bx_nuveo/nuveo_000.png'
import nuveo1 from '../../../assets/visual/bx_nuveo/nuveo_001.png'
import nuveo2 from '../../../assets/visual/bx_nuveo/nuveo_002.png'
import nuveo3 from '../../../assets/visual/bx_nuveo/nuveo_003.png'
import nuveo4 from '../../../assets/visual/bx_nuveo/nuveo_004.png'
function VisualPost17() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={nuveo0} alt="" />
            <img className='content-img' src={nuveo1} alt="" />
            <img className='content-img' src={nuveo2} alt="" />
            <img className='content-img' src={nuveo3} alt="" />
            <img className='content-img' src={nuveo4} alt="" />
            
        </div>
    )
}

export default VisualPost17