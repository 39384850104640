import React from 'react'

import '../ServePost.css'

import aroma0 from '../../../assets/media/uxui_aroma/aroma_000.png'
import aroma1 from '../../../assets/media/uxui_aroma/aroma_001.png'
import aroma2 from '../../../assets/media/uxui_aroma/aroma_002.png'
import aroma3 from '../../../assets/media/uxui_aroma/aroma_003.png'
import aroma4 from '../../../assets/media/uxui_aroma/aroma_004.png'
import aroma5 from '../../../assets/media/uxui_aroma/aroma_005.png'

function MediaPost10() {

    return (
        <div className='serve-content-body'>
            
            <img className='content-img' src={aroma0} alt="" />
            <img className='content-img' src={aroma1} alt="" />
            <img className='content-img' src={aroma2} alt="" />
            <img className='content-img' src={aroma3} alt="" />
            <img className='content-img' src={aroma4} alt="" />
            <img className='content-img' src={aroma5} alt="" />
        </div>
    )
}

export default MediaPost10
