import React from 'react'

import '../ServePost.css'

import watoon0 from '../../../assets/visual/bx_watoon/watoon_000.gif'
import watoon1 from '../../../assets/visual/bx_watoon/watoon_001.png'
import watoon2 from '../../../assets/visual/bx_watoon/watoon_002.png'
import watoon3 from '../../../assets/visual/bx_watoon/watoon_003.png'

function VisualPost11() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={watoon0} alt="" />
            <img className='content-img' src={watoon1} alt="" />
            <img className='content-img' src={watoon2} alt="" />
            <img className='content-img' src={watoon3} alt="" />
            
        </div>
    )
}

export default VisualPost11