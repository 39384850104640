import React from 'react'

import '../ServePost.css'

import hono0 from '../../../assets/visual/graphic_hono/Hono_000.png'
import hono1 from '../../../assets/visual/graphic_hono/Hono_001.png'
import hono2 from '../../../assets/visual/graphic_hono/Hono_002.png'
import hono3 from '../../../assets/visual/graphic_hono/Hono_003.png'
function VisualPost14() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={hono0} alt="" />
            <img className='content-img' src={hono1} alt="" />
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/987983000?background=1`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/987990120?background=1`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <img className='content-img' src={hono2} alt="" />
            <img className='content-img' src={hono3} alt="" />
            
        </div>
    )
}

export default VisualPost14