import React from 'react'

import '../ServePost.css'

import dasan0 from '../../../assets/media/uxui_dasan/dasan_000.png'
import dasan1 from '../../../assets/media/uxui_dasan/dasan_001.png'

function MediaPost8() {

    return (
        <div className='serve-content-body'>
            
            <img className='content-img' src={dasan0} alt="" />
            <img className='content-img' src={dasan1} alt="" />
        </div>
    )
}

export default MediaPost8