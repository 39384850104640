import React from 'react'

import '../ServePost.css'

import puni1 from '../../../assets/media/motion_puni/puni_000.png'
import puni2 from '../../../assets/media/motion_puni/puni_001.png'
import puni2_ from '../../../assets/media/motion_puni/puni_002.gif'
import puni3 from '../../../assets/media/motion_puni/puni_002.png'
import puni4 from '../../../assets/media/motion_puni/puni_003.png'
import puni5 from '../../../assets/media/motion_puni/puni_004.png'
import puni6 from '../../../assets/media/motion_puni/puni_005.png'
import puni7 from '../../../assets/media/motion_puni/puni_006.png'
import ChevronRightArrow from '../../../assets/ChevronArrow.svg'


function MediaPost11() {
    return (
        <div className='serve-content-body'>
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://www.youtube.com/embed/KDkn23Gyd0M`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
                </div>
                <img className='content-img' src={puni2} alt="" />
                <div className='gif-size'><img className='content-img' src={puni2_} alt=""/></div>
                <img className='content-img' src={puni3} alt="" />
                <img className='content-img' src={puni4} alt="" />
                <img className='content-img' src={puni5} alt="" />
                <img className='content-img' src={puni6} alt="" />
                <img className='content-img' src={puni7} alt="" />
                <br></br>
            <br></br>
            <img className='arrow' src={ChevronRightArrow} alt="" /><br></br>
            <a href='https://puni929.wixsite.com/puni/' target={'_blank'}>PUNI 사이트 보러가기 </a>
        
        </div>
    )
}

export default MediaPost11