import React from 'react'

import '../ServePost.css'

import link0 from '../../../assets/fashion/textile_link/link_000.png'
import link1 from '../../../assets/fashion/textile_link/link_001.png'
import link2 from '../../../assets/fashion/textile_link/link_002.png'
import link3 from '../../../assets/fashion/textile_link/link_003.png'
import link4 from '../../../assets/fashion/textile_link/link_004.png'
import link5 from '../../../assets/fashion/textile_link/link_005.png'

function FashionPost1() {
    return (
        <div className='serve-content-body'>
            <img className='content-img' src={link0} alt="" />
            <img className='content-img' src={link1} alt="" />
            <img className='content-img' src={link2} alt="" />
            <img className='content-img' src={link3} alt="" />
            <img className='content-img' src={link4} alt="" />
            <img className='content-img' src={link5} alt="" />
        </div>
    )
}

export default FashionPost1
