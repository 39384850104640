import React from 'react'

import '../ServePost.css'

import ozel1 from '../../../assets/media/uiux_ozel/ozel_001.png'
import ozel2 from '../../../assets/media/uiux_ozel/ozel_002.png'
import ozel3 from '../../../assets/media/uiux_ozel/ozel_003.png'
import ozel4 from '../../../assets/media/uiux_ozel/ozel_004.png'
import ozel5 from '../../../assets/media/uiux_ozel/ozel_005.gif'

function MediaPost7() {

    return (
        <div className='serve-content-body'>
            
            <img className='content-img' src={ozel1} alt="" />
            <img className='content-img' src={ozel2} alt="" />
            <img className='content-img' src={ozel3} alt="" />
            <img className='content-img' src={ozel4} alt="" />
            <img className='content-img' src={ozel5} alt="" />
        </div>
    )
}

export default MediaPost7