import React from 'react'

import '../ServePost.css'

import rescue0 from '../../../assets/fashion/lookbook_rescue/rescue_000.png'
import rescue1 from '../../../assets/fashion/lookbook_rescue/rescue_001.png'
import rescue2 from '../../../assets/fashion/lookbook_rescue/rescue_002.png'

function FashionPost6() {
    return (
        <div className='serve-content-body'>
            <img className='content-img' src={rescue0} alt="" />
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/865332764?background=1`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <img className='content-img' src={rescue1} alt="" />
            <img className='content-img' src={rescue2} alt="" />
        </div>
    )
}

export default FashionPost6
