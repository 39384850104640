import React from 'react'

import '../ServePost.css'

import pure0 from '../../../assets/visual/bx_pure/pure_000.png'
import pure1 from '../../../assets/visual/bx_pure/pure_001.png'
import pure2 from '../../../assets/visual/bx_pure/pure_002.png'
import pure3 from '../../../assets/visual/bx_pure/pure_003.png'
import pure4 from '../../../assets/visual/bx_pure/pure_004.png'

function VisualPost12() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={pure0} alt="" />
            <img className='content-img' src={pure1} alt="" />
            <img className='content-img' src={pure2} alt="" />
            <img className='content-img' src={pure3} alt="" />
            <img className='content-img' src={pure4} alt="" />
            
        </div>
    )
}

export default VisualPost12