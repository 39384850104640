import React from 'react'

import '../ServePost.css'

import neomoda_tex0 from '../../../assets/fashion/textile_neomoda/neomoda_tex_000.png'
import neomoda_tex1 from '../../../assets/fashion/textile_neomoda/neomoda_tex_001.png'
import neomoda_tex2 from '../../../assets/fashion/textile_neomoda/neomoda_tex_002.png'

function FashionPost4() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={neomoda_tex0} alt="" />
            <img className='content-img' src={neomoda_tex1} alt="" />
            <img className='content-img' src={neomoda_tex2} alt="" />
        </div>
    )
}


export default FashionPost4
