import React from 'react'
import '../ServePost.css'

import oytv0 from '../../../assets/visual/contents_oytv/olivTV_000.png'
import oytv1 from '../../../assets/visual/contents_oytv/olivTV_001.png'
import oytv2 from '../../../assets/visual/contents_oytv/olivTV_002.png'
import oytv2_ from '../../../assets/visual/contents_oytv/olivTV_002.gif'
import oytv3 from '../../../assets/visual/contents_oytv/olivTV_003.png'
import oytv3_1 from '../../../assets/visual/contents_oytv/olivTV_003.jpg'
import oytv3_2 from '../../../assets/visual/contents_oytv/olivTV_003_1.jpg'
import oytv3_3 from '../../../assets/visual/contents_oytv/olivTV_003_2.jpg'
import oytv4 from '../../../assets/visual/contents_oytv/olivTV_004.png'
import oytv4_1 from '../../../assets/visual/contents_oytv/olivTV_004.jpg'
import oytv4_2 from '../../../assets/visual/contents_oytv/olivTV_004_1.png'
import oytv4_3 from '../../../assets/visual/contents_oytv/olivTV_004_2.png'
import ChevronRightArrow from '../../../assets/ChevronArrow.svg'


function VisualPost6() {
    return (
        <div className='serve-content-body'>
                <img className='content-img' src={oytv0} alt="" />
                <img className='content-img' src={oytv1} alt="" />
                <img className='content-img' src={oytv2} alt="" />
                <img className='content-img' src={oytv2_} alt="" />
                <img className='content-img' src={oytv3} alt="" />
                <div className='gif-size'><img className='content-img' src={oytv3_1} alt=""/></div><br></br><br></br>
                <img className='content-img' src={oytv3_2} alt="" />
                <img className='content-img' src={oytv3_2} alt="" />
                <img className='content-img' src={oytv3_3} alt="" />
                <img className='content-img' src={oytv4} alt="" />
                <div className='gif-size'><img className='content-img' src={oytv4_1} alt=""/></div><br></br><br></br>
                <img className='content-img' src={oytv4_2} alt="" />
                <img className='content-img' src={oytv4_3} alt="" />
                <br></br>
            <br></br>
            <img className='arrow' src={ChevronRightArrow} alt="" /><br></br>
            <a href='https://www.youtube.com/@cjoliveyoung/featured' target={'_blank'}>올영TV 보러가기 </a>
        
        </div>
    )
}

export default VisualPost6