import React from 'react'

import '../ServePost.css'

import myocean0 from '../../../assets/visual/graphic_myocean/myocean_000.png'
import myocean1 from '../../../assets/visual/graphic_myocean/myocean_001.png'
import myocean2 from '../../../assets/visual/graphic_myocean/myocean_002.png'
import myocean3 from '../../../assets/visual/graphic_myocean/myocean_003.png'
import myocean4 from '../../../assets/visual/graphic_myocean/myocean_004.png'
function VisualPost16() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={myocean0} alt="" />
            <img className='content-img' src={myocean1} alt="" />
            <img className='content-img' src={myocean2} alt="" />
            <div className='content-video'>
            <iframe
                    className='responsive-iframe'
                    src={`https://player.vimeo.com/video/1009822884?background=1`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded vimeo"
                />
            </div>
            <img className='content-img' src={myocean3} alt="" />
            <img className='content-img' src={myocean4} alt="" />
            
        </div>
    )
}

export default VisualPost16