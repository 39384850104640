import React from 'react'

import '../ServePost.css'

import fregmentopia0 from '../../../assets/visual/bx_fregmentopia/fregmentopia_000.png'
import fregmentopia1 from '../../../assets/visual/bx_fregmentopia/fregmentopia_001.png'
import fregmentopia2 from '../../../assets/visual/bx_fregmentopia/fregmentopia_002.png'

function VisualPost8() {

    return (
        <div className='serve-content-body'>
            <img className='content-img' src={fregmentopia0} alt="" />
            <img className='content-img' src={fregmentopia1} alt="" />
            <img className='content-img' src={fregmentopia2} alt="" />
        </div>
    )
}

export default VisualPost8