import React from 'react'

import '../ServePost.css'

import sibf1 from '../../../assets/visual/graphic_sibf/sibf_001.png'
import sibf2 from '../../../assets/visual/graphic_sibf/sibf_002.png'
import sibf3 from '../../../assets/visual/graphic_sibf/sibf_003.png'
import sibf4 from '../../../assets/visual/graphic_sibf/sibf_004.png'
import sibf5 from '../../../assets/visual/graphic_sibf/sibf_005.png'
import sibf6 from '../../../assets/visual/graphic_sibf/sibf_006.png'
import sibf7 from '../../../assets/visual/graphic_sibf/sibf_007.jpg'

function VisualPost1() {
    return (
        <div className='serve-content-body'>
            <img className='content-img' src={sibf1} alt="" />
            <img className='content-img' src={sibf2} alt="" />
            <img className='content-img' src={sibf3} alt="" />
            <img className='content-img' src={sibf4} alt="" />
            <img className='content-img' src={sibf5} alt="" />
            <img className='content-img' src={sibf6} alt="" />
            <img className='content-img' src={sibf7} alt="" />
        </div>
    )
}

export default VisualPost1