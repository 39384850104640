import React, { useState, useEffect, useRef } from 'react'
import Matter from "matter-js";
import { motion } from "framer-motion";

import './Serve.css'

import VisualLoading from '../Loadings/VisualLoading'
import NavHeader from '../Components/NavHeader'
import CircleCard from '../Components/CircleCard'
import SecondFooter from '../Components/SecondFooter'
import MobileNavHeader from '../Components/MobileNavHeader'
import MobileNav from '../Components/MobileNav'

import visual from '../assets/icons/visual_icon.svg'
import pill from '../assets/pills/visual_pill.svg'
import arrow_L from '../assets/arrow_l.svg'
import arrow_R from '../assets/arrow_r.svg'

import whitenight from '../assets/visual/bx_whitenight/whitenight_thumbnail.png'
import oylive from '../assets/visual/contens_oylive/oylive_thumbnail.png'
import fregmentopia from '../assets/visual/bx_fregmentopia/fregmentopia_thumbnail.png'
import counselor from '../assets/visual/motion_counselor/counselor_thumbnail.png'
import sibf from '../assets/visual/graphic_sibf/sibf_thumbnail.png'
import street from '../assets/visual/graphic_street/street_thumbnail.png'
import pyeonteilbar from '../assets/visual/graphic_pyeonteilbar/Pyeonteilbar_thumbnail.png'
import OYTV from '../assets/visual/contents_oytv/olive0_thumbnail.png'
import www from '../assets/visual/bx_www/www_thumbnail.png'
import watoon from '../assets/visual/bx_watoon/watoon_thumbnail.png'
import pure from '../assets/visual/bx_pure/pure_thumbnail.png'
import rest from '../assets/visual/graphic_takearest/takearest_thumbnail.png'
import hono from '../assets/visual/graphic_hono/hono_thumbnail.png'
import chadadam from '../assets/visual/bx_chadadam/chadadam_thumbnail.png'
import myocean from '../assets/visual/graphic_myocean/myocean_thumbnail.png'
import nuveo from '../assets/visual/bx_nuveo/nuveo_thumbnail.png'

export default function Visual() {

    const matterRef = useRef(null);

    const serveType = 'visual'
    const itemList = [
        { id: 17, title: 'NUVEO', type: 'BX', image: nuveo},
        { id: 16, title: 'My Ocean', type: 'Graphic', image: myocean},
        { id: 15, title: 'Cha Da Dam', type: 'BX', image: chadadam},
        { id: 14, title: 'HONO', type: 'Graphic', image: hono},
        { id: 3, title: 'Within a trend', type: 'Graphic', image: street},
        { id: 13, title: 'Take a rest', type: 'Graphic', image: rest},
        { id: 12, title: 'Pure Collection', type: 'BX', image: pure},
        { id: 11, title: 'Watoon', type: 'BX', image: watoon},
        { id: 10, title: 'White night', type: 'BX', image: whitenight},
        { id: 8, title: 'Fregmentopia', type: 'BX', image: fregmentopia},
        { id: 5, title: 'WWW', type: 'BX', image: www },
        { id: 9, title: 'Oliveyoung Live', type: 'Contents', image: oylive },
        { id: 6, title: 'Oliveyoung TV', type: 'Contents', image: OYTV },
        { id: 4, title: 'Pyeonteilbar', type: 'Graphic', image: pyeonteilbar },
        //{ id: 7, title: 'Omni conselor', type: 'Contents', image: counselor },
        //{ id: 1, title: '2021 SIBF', type: 'Graphic', image: sibf },
    ]

    const [isLoading, setIsLoading] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filter, setFilter] = useState('all')

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    useEffect(() => {
        const reloadCount = sessionStorage.getItem('reloadCount');
        if (reloadCount < 2) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            window.location.reload(false);
        } else {
            sessionStorage.removeItem('reloadCount');
        }
    }, [])

    const [itemPosition, setItemPosition] = useState(0);
    const [x, setX] = useState(0); //188.5
    const [y, setY] = useState(0);
    const [rotate, setRotate] = useState(0);
    function moveItem_R() {
        if (itemPosition < itemList.length - 1) {
          setItemPosition(itemPosition + 1);
          setX(x - 324);
        } else if (itemPosition === itemList.length - 1) {
          setItemPosition(0);
          setX(0);
        }
      
        console.log('x', x);
        console.log('itemPositon', itemPosition);
      }
      
      function moveItem_L() {
        if (itemPosition > 0) {
          setItemPosition(itemPosition - 1);
          setX(x + 324);
        } else if (itemPosition === 0) {
          setItemPosition(itemList.length - 1);
          setX(-(itemList.length - 1) * 324);
        }
      
        console.log('x', x);
        console.log('itemPositon', itemPosition);
      }
    
    useEffect(() => {
        setItemPosition(0);
        setX(0);
        setY(0);
        setRotate(0);
      }, [filter]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
        setTimeout(() => {
            setIsLoading(false)
        }, 1500);

        var body = document.body,
        html = document.documentElement;

        var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight) - 190;

        var Engine = Matter.Engine,
            Render = Matter.Render,
            World = Matter.World,
            MouseConstraint = Matter.MouseConstraint,
            Mouse = Matter.Mouse,
            Bodies = Matter.Bodies;

        var engine = Engine.create()

        var render = Render.create({
            element: matterRef.current,
            engine: engine,
            options: {
                width: window.innerWidth,
                height: height,
                wireframes: false,
                background: 'transparent',
                wireframeBackground: 'transparent'
            }
        });

        var topWall = Bodies.rectangle(window.innerWidth / 2 - 50, -50, window.innerWidth - 50, 50, { isStatic: true, render: { fillStyle: "fff" } }),
            bottomWall = Bodies.rectangle(window.innerWidth / 2, height, window.innerWidth, 50, { isStatic: true, render: { fillStyle: "fff" } }),
            leftWall = Bodies.rectangle(window.innerWidth + 50, height / 2, 50, height, { isStatic: true, render: { fillStyle: "fff" } }),
            rightWall = Bodies.rectangle(-50, height / 2, 50, height, { isStatic: true, render: { fillStyle: "fff" } })

        const pill1 = Bodies.rectangle(650, 400, 90, 45, {
            restitution: 0.5,
            chamfer: 1000,
            render: { sprite: { texture: pill } }
        }),
            pill2 = Bodies.rectangle(600, 400, 90, 45, {
                restitution: 0.5,
                chamfer: 1000,
                render: { sprite: { texture: pill } }
            }),
            pill3 = Bodies.rectangle(950, 400, 90, 45, {
                restitution: 0.5,
                chamfer: 1000,
                render: { sprite: { texture: pill } }
            }),
            pill4 = Bodies.rectangle(1000, 400, 90, 45, {
                restitution: 0.5,
                chamfer: 1000,
                render: { sprite: { texture: pill } }
            }),
            pill5 = Bodies.rectangle(900, 400, 90, 45, {
                restitution: 0.5,
                chamfer: 1000,
                render: { sprite: { texture: pill } }
            });

            pill1.position.x = 650;
            pill1.position.y = 400;
            pill2.position.x = 600;
            pill2.position.y = 400;
            pill3.position.x = 950;
            pill3.position.y = 400;
            pill4.position.x = 1000;
            pill4.position.y = 400;
            pill5.position.x = 900;
            pill5.position.y = 400;

        World.add(engine.world, [topWall, bottomWall, leftWall, rightWall, pill1, pill2, pill3, pill4, pill5]);

        var mouse = Mouse.create(render.canvas),
            mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    render: {
                        visible: false
                    }
                }
            });
        mouseConstraint.mouse.element.removeEventListener("mousewheel", mouseConstraint.mouse.mousewheel);
        mouseConstraint.mouse.element.removeEventListener("DOMMouseScroll", mouseConstraint.mouse.mousewheel);
        render.mouse = mouse;

        World.add(engine.world, mouseConstraint);
        Render.lookAt(render, {
            min: { x: 0, y: 0 },
            max: { x: window.innerWidth, y: height }
        });

        Matter.Runner.run(engine);
        Render.run(render);
    }, [])

    return (
        <div className='page'>
            {isLoading ? <VisualLoading /> : null}
            <div id='visual' className='page'>
                <NavHeader isNav={true} isAbout={false} isBlog={false} />
                <MobileNavHeader setIsModalOpen={setIsModalOpen} />
                {isModalOpen ? <MobileNav setIsModalOpen={setIsModalOpen} /> : null}
                <div className='serveBody flex-col'>
                    <div className='matter' ref={matterRef} />
                    <img id='visualIcon' src={visual} alt="" />
                    <div className='serveText'>VISUAL DESIGN</div>
                    <div className="filter flex">
                        <div className='filterItem' style={{ color: filter === 'all' ? '#161619' : '#7E7E86', fontWeight: filter === 'all' ? '700' : null }} onClick={() => setFilter('all')}>ALL</div>
                        <div className='filterItem' style={{ color: filter === 'BX' ? '#161619' : '#7E7E86', fontWeight: filter === 'BX' ? '700' : null }} onClick={() => setFilter('BX')}>BX</div>
                        <div className='filterItem' style={{ color: filter === 'Contents' ? '#161619' : '#7E7E86', fontWeight: filter === 'Contents' ? '700' : null }} onClick={() => setFilter('Contents')}>Contents</div>
                        <div className='filterItem' style={{ color: filter === 'Graphic' ? '#161619' : '#7E7E86', fontWeight: filter === 'Graphic' ? '700' : null }} onClick={() => setFilter('Graphic')}>Graphic</div>
                    </div>
                    <hr className='filter-hr' />
                    <div className='cards flex'>
                        <div className='cards-empty-space-left' />
                        <img className='cards-arrow_L' src={arrow_L} alt="" onClick={moveItem_L} />
                        <img className='cards-arrow_R' src={arrow_R} alt="" onClick={moveItem_R} />
                        <div className='flex cards-container'>
                            <motion.div
                                className='flex motion-div'
                                animate={{ x, y, rotate }}
                                transition={{ type: "spring", stiffness: 300, damping: 30 }}
                            >
                                {itemList.filter(item => item.type === filter || filter === 'all').map(filteredItem => (
                                    <div className='flex'>
                                        <CircleCard serveType={serveType} id={filteredItem.id} type={filteredItem.type} title={filteredItem.title} image={filteredItem.image} />
                                        <div style={{ width: '5rem' }} />
                                    </div>
                                ))}
                            </motion.div>
                        </div>
                    </div>
                </div>
                <SecondFooter fashion={false} visual={true} media={false} />
            </div>
        </div>
    )
}
